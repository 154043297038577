import React, { Component } from "react"

import { Link } from "gatsby"
import Main from "../components/404/Main"
import Hero from  "../components/hero/Hero"
import { Helmet } from "react-helmet"
import Header from "../components/header/Header"
import ISI from '../components/isi/ISI'
import Footer from '../components/footer/Footer'
import SEO from "../components/seo"

import Loader from "../components/loader"


export default class NotFoundPage extends Component {

  constructor(props) {
    super(props)
    this.state = {
       stickyNav: true,
       isMobile: false,
       modal:  null,
       footnotes: [0,1,2]
    }
  }

  componentDidMount() {
    let bool = window.matchMedia("only screen and (max-width: 1023px)").matches;
    this.setState({ isMobile: bool })
  }


  goModal = (str) => {
    // console.log('clicked')
    // let str = evt.target.dataset.modal;
    this.setState(() => ({ modal: str }))
  }

  modalClosed = () => {
    this.setState(() => ({ modal: null }))
  }


  render() {
    return (
        <>
        <Loader>
        <SEO
          title="Page Not Found"
          keywords={[""]}
          description=""/>
        	<Header isMobile={this.state.isMobile} hideNav={this.state.hideNav} goModal={this.goModal}/>
        	<Hero>
        	  <h1 className="headline">Sorry! This page doesn’t exist</h1>

      		</Hero>
      		<Main/>
      		<div className="notFound-container">
	      			<h2 className="cv-headline">Find what you’re looking for here:</h2>

	      			<div className="error-btn-container">
		      			<Link className="homeBtn" to="/cv-outcomes/">CV Outcomes</Link>
	            	<Link className="homeBtn" to="/why-vascepa/">Why VASCEPA?</Link>
	            	<Link className="homeBtn" to="/getting-patients-started/">Getting Patients Started</Link>
	      			</div>
	      	</div>

      		<ISI disclamer="Visual representations are for illustrative purposes only. The capsule shown is not an identical representation of the product."/>
          	<Footer />
            </Loader>
        </>
    )
  }
}
